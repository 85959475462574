import React, { Fragment } from 'react'

import acast from 'assets/img/acast.png'
import spotify from 'assets/img/spotify.png'
import google from 'assets/img/google.png'
import apple from 'assets/img/apple.png'
import tunein from 'assets/img/tune-in.png'

/* Do not change the keys */
const logos = {
  acast: acast,
  'Apple Podcasts': apple,
  Spotify: spotify,
  'Google Podcasts': google,
  TuneIn: tunein
}

const FeedItem = ({ type, url }) => (
  <li
    className='c-feed-logos__item o-list-inline__item u-margin-right-small u-margin-bottom-small'
    data-key={type}
  >
    <a
      href={url}
      target='_blank'
      rel='noopener noreferrer'
      className='u-dis-b'
    >
      <img
        src={logos[type]}
        className='u-dis-b'
        alt={`The ${type} logo.`}
      />
    </a>
  </li>
)

const ShowFeeds = ({ feeds, rssFeedUrl, trackUrl, hideRssShareEpisodes }) => (
  <Fragment>
    <h4
      className='u-fs-14 u-margin-bottom-small'
    >
      Where to listen:
    </h4>
    { feeds &&
      <ul
        className='c-feed-logos o-list-inline u-margin-bottom-small'
      >
        {
          feeds.map(({ type, url }, key) => (
            <FeedItem
              type={type}
              url={url}
              key={key}
            />
          ))
        }
      </ul>
    }
    <ul
      className={`o-list-inline ${trackUrl ? `o-list-inline--with-separator u-margin-left-none` : ``}`}
    >
      {(!hideRssShareEpisodes && rssFeedUrl) && <li>
        <a
          href={rssFeedUrl}
          target='_blank'
          rel='noopener noreferrer'
          className='u-color-white u-fw-bold o-action-link u-fs-13 u-dis-ib'
        >
          RSS Feed
        </a>
      </li>}
      {
        trackUrl &&
        <li>
          <a
            href={trackUrl}
            target='_blank'
            rel='noopener noreferrer nofollow'
            className='u-fs-14 u-color-white o-action-link'
          >
            Download this show
          </a>
        </li>
      }
    </ul>
  </Fragment>
)

export default ShowFeeds
